.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 52px 32px 0;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  width: unquote('min(550px, 100%)');
  text-align: center;
}

.main-list {
  color: white;
}

.home-logo {
  width: unquote('min(550px, 100%)');
}

.home-title {
  margin-top: 2.5rem;
  font-size: 2.7rem;
  color: #43b02a;
}

.title {
  font-size: 2.7rem;
  color: #43b02a;
  margin-bottom: 26px;
  line-height: 1.5;
}

p {
  font-size: 1.2rem;
  color: $light;
}

@media (max-width: 600px) {
  .home-title {
    font-size: 2rem;
  }
}
