.nav {
  position: fixed;
  padding: 15px 0;
  width: 100%;
  background-color: rgba(#161617, 0.25);
  backdrop-filter: blur(10px);
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    list-style-type: none;
    > * + * {
      margin-left: 20px;
    }
  }
  a {
    color: white;
    transition: color 0.5s;
    &:hover {
      color: #43b02a;
    }
  }
}

// .services {
//   margin-top: 40px;
//   padding: 20px;
//   border: 1px solid #43b02a;
//   border-radius: 5px;
//   color: white;
//   span {
//     color: #43b02a;
//   }
//   > * + * {
//     margin-top: 20px;
//   }
// }

.footer {
  padding: 10px 0;
  background-color: #161617;
  p {
    text-align: center;
    color: white;
    font-size: 0.8em;
  }
}
